import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pages"]

  connect() {
    this.updatePagination()
  }

  updatePagination() {
    const totalItems = this.pagesTargets.length
    this.pagesTargets.forEach((target, index) => {
      target.textContent = `Page ${index + 1} of ${totalItems}`
    })
  }
}