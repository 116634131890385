import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  submit(e) {
    // Use requestSubmit instead of submit to trigger native validation
    // https://discuss.hotwired.dev/t/triggering-turbo-frame-with-js/1622/15
    this.element.formNoValidate = true;
    // Form should be submitted using Turbo
    this.element.requestSubmit();
  }

  submitDebounced() {
    var form = this.element;
    _.debounce(function () {
      form.novalidate = true;
      form.requestSubmit();
    }, 1000)();
  }
}
