import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "info", "radio"];
  static classes = ["hidden", "selected"];

  // https://onrails.blog/2020/09/25/radio-selection-unselect/
  connect() {
    document.addEventListener("toggle-radio", this.handleSelection.bind(this));
  }

  toggle(event) {
    event.stopImmediatePropagation();

    const selectionEvent = new CustomEvent("toggle-radio", {
      detail: {
        name: event.target.name,
        value: event.target.value,
      },
    });
    document.dispatchEvent(selectionEvent);
    return false;
  }

  handleSelection(event) {
    if (event.detail.name === this.radioTarget.name) {
      const selected = event.detail.value === this.radioTarget.value;
      if (this.hasInfoTarget) {
        this.infoTarget.classList.toggle(this.hiddenClass, !selected);
      }
      if (this.hasContainerTarget) {
        this.containerTarget.classList.toggle(this.selectedClass, selected);
      }
    }
  }
}
