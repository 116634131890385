import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "output"];

  connect() {
    this.update();
  }

  update() {
    this.outputTarget.innerHTML = this.sourceTarget.value;
  }
}
