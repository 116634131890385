import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { sitekey: String, action: String }
  
  connect() {
    this.initializeTurnstile()
  }

  initialize() {
  }

  disconnect() {
    // Clear any pending retry attempts when controller disconnects
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout)
    }
  }

  initializeTurnstile() {
    const turnstileContainer = this.element
    
    // Don't reinitialize if already rendered
    if (turnstileContainer.innerHTML.trim() !== '') {
      return
    }

    // Check if turnstile is available
    if (typeof turnstile === "undefined") {
      // Retry after 100ms if turnstile is not loaded yet
      this.retryTimeout = setTimeout(() => {
        this.initializeTurnstile()
      }, 100)
      return
    }

    // Render turnstile when available
    turnstile.render(turnstileContainer, {
      sitekey: this.sitekeyValue,
      action: this.actionValue
    })
  }
}
