import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"];
  static values = { classlist: String };

  toggleItem(event) {
    event.preventDefault();
    event.stopPropagation();

    for (let el of this.itemTargets) {
      el.classList.toggle(this.classList);
    }
  }

  showItem(event) {
    event.preventDefault;
    for (let el of this.itemTargets) {
      el.classList.remove(this.classList);
    }
  }

  hideItem(event) {
    event.preventDefault;
    for (let el of this.itemTargets) {
      el.classList.add(this.classList);

      for (let checkbox of el.querySelectorAll("input[type=checkbox]")) {
        checkbox.checked = false;
      }
    }
  }

  get classList() {
    return this.classlistValue || "hidden";
  }
}
