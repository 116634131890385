import _ from "lodash";

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output", "cursor"];
  static values = {
    phrases: Array,
    loop: Boolean,
    speed: Number,
  };

  connect() {
    this.typewrite();
  }

  typewrite() {
    var cursor = document.createElement("span");
    cursor.innerHTML = "|";
    cursor.classList.add("font-light");
    this.outputTarget.after(cursor);

    let phrases = this.phrasesValue;
    let output = this.outputTarget;
    // let cursor = this.cursor();
    var index = 0,
      isAdding = true,
      phraseIndex = 0;

    function playAnim() {
      setTimeout(
        function () {
          // set the text of output to a substring of
          // the textToBeTyped using index.
          output.innerText = phrases[phraseIndex].slice(0, index);
          if (isAdding) {
            // adding text
            if (index > phrases[phraseIndex].length) {
              // no more text to add
              isAdding = false;
              // play cursor blink animation
              cursor.classList.add("blink");
              //break: wait 2s before playing again
              setTimeout(function () {
                cursor.classList.remove("blink");
                playAnim();
              }, 2000);
              return;
            } else {
              // increment index by 1
              index++;
            }
          } else {
            // removing text
            if (index === 0) {
              // no more text to remove
              isAdding = true;
              phraseIndex = (phraseIndex + 1) % phrases.length;
            } else {
              // decrement index by 1
              index--;
            }
          }
          // call itself
          playAnim();
        },
        isAdding ? 60 : 30
      );
    }
    playAnim();
  }
}
