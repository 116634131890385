import { Controller } from "stimulus";

const upKey = 38;
const downKey = 40;
const enterKey = 13;
const tabKey = 9;
const navigationKeys = [upKey, downKey, enterKey, tabKey];

export default class extends Controller {
  static targets = ["result"];

  connect() {
    this.currentResultIndex = 0;
    this.selectCurrentResult();
  }

  navigateResults(event) {
    if (!navigationKeys.includes(event.keyCode)) {
      return;
    }

    event.preventDefault();

    switch (event.keyCode) {
      case downKey:
        this.selectNextResult();
        break;
      case upKey:
        this.selectPreviousResult();
        break;
      case enterKey:
        this.getSelectedResult();
        break;
      case tabKey:
        this.getSelectedResult();
        break;
    }
  }

  // private

  selectCurrentResult() {
    this.resultTargets.forEach((element, index) => {
      element.classList.toggle(
        "search__result--current",
        index == this.currentResultIndex
      );
    });
  }

  selectNextResult() {
    if (this.currentResultIndex < this.resultTargets.length - 1) {
      this.currentResultIndex++;
      this.selectCurrentResult();
    }
  }

  selectPreviousResult() {
    if (this.currentResultIndex > 0) {
      this.currentResultIndex--;
      this.selectCurrentResult();
    }
  }

  getClickedResult() {
    var id = event.target.dataset.id;
    this.idTarget.value = id;
    this.textTarget.value = event.target.textContent;
    this.searchController.close();
  }

  getSelectedResult() {
    var target = this.resultTargets[this.currentResultIndex];
    var id = target.dataset.id;
    this.idTarget.value = id;
    this.textTarget.value = target.textContent;
    this.searchController.close();
  }

  get idTarget() {
    return this.searchController.idTarget;
  }

  get textTarget() {
    return this.searchController.queryTarget;
  }

  get searchController() {
    return this.element.closest("[data-controller~=search]").search;
  }
}
