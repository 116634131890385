import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    id: String,
    returnUrl: String,
    createUrl: String,
    clientSecret: String,
    address: Object,
    email: String,
    name: String,
    account: String,
  };

  static targets = ["form", "card", "errors", "submit"];

  connect() {
    this.returnHref = window.location.href;
    this.mountStripe();

    this.token = document.querySelector('meta[name="csrf-token"]').content;
  }

  async confirmSetup(event) {
    event.preventDefault();
    this.clearMessage();
    this.submitTargets.forEach((submit) => (submit.disabled = "disabled"));

    const form = event.target;
    const url = form.action;
    const formData = new FormData(form);
    const entries = Object.fromEntries(formData.entries());
    const json = JSON.stringify({ payment_method: entries });

    // Submit this form to our Rails app.
    const response = await fetch(url, {
      method: "POST",
      body: json,
      headers: {
        "X-CSRF-Token": this.token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    // Parse the response.headers.get("Location") and add the returnUrl as a param:
    const returnUrl = new URL(response.headers.get("Location"));
    returnUrl.searchParams.append("returnHref", this.returnHref);

    // Send confirmSetup to Stripe.
    const { error } = await this.stripe.confirmSetup({
      elements: this.elements,
      confirmParams: {
        return_url: returnUrl.href,
      },
    });

    if (error) {
      this.submitTargets.forEach((submit) =>
        submit.removeAttribute("disabled")
      );
      if (error.type === "card_error" || error.type === "validation_error") {
        this.showMessage(error.message);
      } else {
        this.showMessage("An unexpected error occurred.");
      }
    }
  }

  showMessage(message) {
    this.errorsTarget.textContent = message;
  }

  clearMessage() {
    this.errorsTarget.textContent = "";
  }

  mountStripe = () => {
    const pk = document.querySelector('meta[name="stripe-pk"]').content;
    const clientSecret = this.clientSecretValue;

    this.stripe = Stripe(pk, {
      stripeAccount: this.data.get("account"),
    });

    const appearance = { theme: "stripe" };

    this.elements = this.stripe.elements({ appearance, clientSecret });

    const paymentElementOptions = {
      layout: "tabs",
      defaultValues: {
        billingDetails: {
          email: this.emailValue,
          name: this.nameValue,
          address: this.addressValue,
        },
      },
    };

    this.card = this.elements.create("payment", paymentElementOptions);
    this.card.mount(this.cardTarget);
  };
}
