import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["code"];

  submitCode(e) {
    e.preventDefault();
    var url = this.element.action + "/" + this.codeTarget.value;
    Turbo.visit(url);
  }
}
