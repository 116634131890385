import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["filter"];

  connect() {}

  change(e) {
    this.element.submit();
  }
}
