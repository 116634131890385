import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "installmentAmount",
    "installments",
    "amount",
    "pledgeFields",
    "recurringFields",
    "commitment",
  ];
  static classes = ["hidden"];

  connect() {
    this.toggleCommitment();
    this.syncAmount();
  }

  toggleCommitment() {
    // get the value of the commitment target radio button
    var selectedCommitment = this.commitmentTargets.find(
      (element) => element.checked
    );
    var commitment = (selectedCommitment || this.commitmentTargets[0]).value;

    // Hide all the fields...
    this.pledgeFieldsTargets
      .concat(this.recurringFieldsTargets)
      .forEach((element) => {
        element.classList.add(this.hiddenClass);
      });

    if (commitment == "one_time") {
      this.activeElement = this.amountTarget;

    } else if (commitment == "recurring") {
      this.activeElement = this.amountTarget;

      this.recurringFieldsTargets.forEach((element) => {
        element.classList.remove(this.hiddenClass);
      });
    } else if (commitment == "pledge") {
      this.activeElement = this.installmentAmountTarget;

      this.pledgeFieldsTargets.forEach((element) => {
        element.classList.remove(this.hiddenClass);
      });
    }
  }

  setActiveElement(event) {
    this.activeElement = event.target;
  }

  formatAmountField(event) {
    const elem = event.target;
    var amount = elem.value;
    amount = parseFloat(amount.replace(/[^0-9.]/g, "")) || 0;
    amount = this.formatAmount(amount);
    elem.value = amount;
  }

  syncAmount() {
    var amount, installmentAmount;
    if (this.activeElement == this.installmentAmountTarget) {
      installmentAmount = this.toNumber(
        this.installmentAmountTarget.value || 0
      );
      amount = this.installmentsTarget.value * installmentAmount;
      this.amountTarget.value = this.formatAmount(amount);
    } else {
      amount = this.toNumber(this.amountTarget.value || 0);
      installmentAmount = amount / this.installmentsTarget.value;
      this.installmentAmountTarget.value = this.formatAmount(installmentAmount);
    }
  }

  toNumber(amount) {
    if (typeof amount === "string") {
      return parseFloat(amount.replace(/[^0-9.]/g, "")) || 0;
    } else {
      return amount;
    }
  }

  formatAmount(amount) {
    return amount.toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    );
  }
}
