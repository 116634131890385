import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "preview", "prompt"];
  static classes = ["dragging", "prompting"];

  connect() {
    this.element.addEventListener("dragover", (event) => {
      this.dragover(event);
    });

    this.element.addEventListener("dragleave", (event) => {
      this.dragleave(event);
    });

    this.element.addEventListener("drop", (event) => {
      this.drop(event);
    });

    this.fieldTarget.addEventListener("change", (event) => {
      this.preview();
    });
  }

  remove(event) {
    event.preventDefault();
    this.element.classList.add(this.promptingClass);
  }

  toggleRemove(event) {
    this.element.classList.toggle(this.promptingClass);
    var label = event.target.parentElement.querySelector("label");
    if (event.target.checked) {
      label.innerHTML = "Undo";
    } else {
      label.innerHTML = "Remove";
    }
  }

  preview(image) {
    if (typeof image == "undefined") {
      var files = this.fieldTarget.files;
      var image = files[0];
    }

    if (image) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.previewTarget.src = e.target.result;
      };
      reader.readAsDataURL(image);

      this.element.classList.remove(this.promptingClass);
    }
  }

  drop(event) {
    event.preventDefault();
    var files = event.dataTransfer.files;
    this.fieldTarget.files = files;
    this.preview(files[0]);
  }

  dragover(event, cont) {
    event.preventDefault();
    event.target.classList.add(...this.draggingClass.split(" "));
  }

  dragleave(event) {
    event.preventDefault();
    event.target.classList.remove(...this.draggingClass.split(" "));
  }

  get accept() {
    return this.fieldTarget.accept.split(",");
  }
}
