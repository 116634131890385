import { Controller } from "stimulus";

export default class extends Controller {
  click(event) {
    if (!["BUTTON", "A"].includes(event.target.tagName)) {
      event.preventDefault();
      Turbo.visit(this.url);
    }
  }

  get url() {
    return this.data.get("url");
  }
}
