document.addEventListener("turbo:submit-start", (event) => {
  confirmSubmission(event).then(() => {
    // show progress bar and set submit state here.
    // this block is optional.
  });
});

function confirmSubmission(event) {
  const button = event.target.querySelector("[data-confirm]");
  const message = button?.dataset?.confirm;

  return new Promise((resolve) => {
    if (!message || confirm(message)) {
      resolve();
    } else {
      event.detail.formSubmission.stop();
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  });
}
