import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["quantity", "amount", "total"];

  connect() {}

  changeQuantity() {
    var newAmount = this.quantityTarget.value * this.data.get("amount");

    this.totalTarget.innerHTML = "$" + newAmount + ".00";
  }
}
