import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["source", "label"];

  copy(e) {
    e.preventDefault();
    this.sourceTarget.select();
    document.execCommand("copy");

    var previousLabel = this.label.innerHTML;
    this.label = "Copied!";

    // https://lodash.com/docs/4.17.15#delay
    _.delay(
      function (label, text) {
        label.innerHTML = text;
      },
      2000,
      this.label,
      previousLabel
    );
  }

  get label() {
    return this.labelTarget;
  }

  set label(text) {
    this.labelTarget.innerHTML = text;
  }
}
