import { Controller } from "stimulus";

// https://docs.btcpayserver.org/CustomIntegration/
export default class extends Controller {
  static values = {
    id: String,
    returnUrl: String,
  };

  connect() {
    console.log("btcpay_invoice_controller.connect", {
      form_target: this.formTarget,
      returnUrl: this.returnUrlValue,
    });
    this.loadInvoice();

    // window.btcpay.onModalWillEnter(this.handleCallback);
    // window.btcpay.onModalWillLeave(this.handleCallback);
    window.btcpay.onModalReceiveMessage(this.handleCallback);
  }

  loadInvoice() {
    window.btcpay.showInvoice(this.idValue);
  }

  handleCallback(event) {
    console.log({ event });

    if (event.data && event.data.status == "paid") {
      console.log(event.data.invoiceId);
      Turbo.visit(this.returnUrlValue);
    }
  }
}
