import { Controller } from "stimulus";
// Import colors from tailwind
import colors from "tailwindcss/colors";

export default class extends Controller {
  static values = {
    id: String,
    returnUrl: String,
    prepareUrl: String,
    clientSecretUrl: String,
    paymentMethodType: String,
    address: Object,
    name: String,
    email: String,
    account: String,
  };

  static outlets = ["checkout"];

  static targets = ["card", "submit", "errors"];

  connect() {
    this.mountStripe();
  }

  disconnect() {}

  postFrameHeight() {
    var height = document.body.clientHeight;
    window.parent.postMessage(
      {
        frameHeight: height,
      },
      "*",
    );
  }

  enableSubmitButtons() {
    this.submitTargets.forEach((submit) => submit.removeAttribute("disabled"));
  }

  async confirmPayment(event) {
    event.preventDefault();
    this.clearMessage();
    this.validateFormElements();
    this.submitTargets.forEach((submit) => (submit.disabled = "disabled"));

    this.token = document.querySelector('meta[name="csrf-token"]').content;

    // Prepare the checkout and eusure dependent objects are saved before triggering Stripe callbacks.
    // await fetch(this.prepareUrlValue);
    // fetch prepareUrlValue and show errors if not success.
    const prepareRequest = await fetch(this.prepareUrlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (prepareRequest.ok) {
      // Elements must be submitted before confirming the setupIntent
      // https://docs.stripe.com/js/elements/submit
      this.elements.submit();
      this.confirmStripeSetup();
    } else {
      const { errors } = await prepareRequest.json();
      this.showMessage(errors);
      this.enableSubmitButtons();
      return;
    }
  }

  async confirmStripeSetup() {
    // https://docs.stripe.com/js/setup_intents/confirm_setup
    const { error } = await this.stripe.confirmSetup({
      elements: this.elements,
      clientSecret: this.clientSecret,
      confirmParams: {
        return_url: this.returnUrlValue,
      },
    });

    if (error) {
      this.submitTargets.forEach((submit) =>
        submit.removeAttribute("disabled"),
      );
      if (error.type === "card_error" || error.type === "validation_error") {
        this.showMessage(error.message);
      } else {
        this.showMessage("An unexpected error occurred.");
      }
    }
  }

  validateFormElements() {
    if (
      !this.checkoutOutletElements.every((outlet) => outlet.reportValidity())
    ) {
      return;
    }
  }

  showMessage(message) {
    this.errorsTarget.textContent = message;
  }

  clearMessage() {
    this.errorsTarget.textContent = "";
  }

  async mountStripe() {
    const pk = document.querySelector('meta[name="stripe-pk"]').content;

    // Fetch the clientSecret from the client_secret url.
    // Client_secret is returned by the SetupIntent, which must be created in the backend API.
    // PUT /organization_slug/checkouts/:checkout_id/client_secret
    const response = await fetch(this.clientSecretUrlValue, { method: "GET" });
    const { clientSecret } = await response.json();
    this.clientSecret = clientSecret;

    this.stripe = Stripe(pk, {
      stripeAccount: this.accountValue,
    });

    // https://docs.stripe.com/elements/appearance-api?platform=web#variables
    this.appearance = {
      theme: "stripe",
      variables: {
        colorPrimary: colors.blue[600],
        fontFamily: "Nunito, Ideal Sans, system-ui, sans-serif",
      },
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Nunito:400,600,700",
        },
      ],
    };

    // Uncaught (in promise) IntegrationError: Invalid value for elements(): payment_method_types.0 should be one of the following strings: billie, amazon_pay, alipay, alma, affirm, afterpay_clearpay, au_becs_debit, acss_debit, bacs_debit, bancontact, blik, boleto, card, cashapp, crypto, customer_balance, eps, fpx, giropay, grabpay, ideal, klarna, konbini, mobilepay, multibanco, ng_bank, ng_bank_transfer, ng_card, ng_market, ng_ussd, nz_bank_account, oxxo, p24, pay_by_bank, paypal, payto, rechnung, sepa_debit, sofort, south_korea_market, kr_card, kr_market, kakao_pay, naver_pay, payco, swish, three_d_secure, twint, upi, us_bank_account, wechat_pay, paynow, pix, promptpay, revolut_pay, sunbit, satispay, netbanking, id_bank_transfer, link, link_card_brand, demo_pay, zip. You specified: bank.
    this.elements = this.stripe.elements({
      appearance: this.appearance,
      mode: "setup",
      payment_method_types: [this.paymentMethodTypeValue],
    });

    const paymentElementOptions = {
      layout: "tabs", // or 'accordion'
      style: {
        base: {
          fontFamily: "Nunito",
          fontSize: "16px",
        },
      },
      defaultValues: {
        billingDetails: {
          email: this.emailValue,
          name: this.nameValue,
          address: this.addressValue,
        },
      },
    };

    this.card = this.elements.create("payment", paymentElementOptions);

    const controller = this;
    this.card.on("ready", (event) => {
      controller.postFrameHeight();
    });

    this.card.mount(this.cardTarget);
    this.enableSubmitButtons();
  }
}
