import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  toggle(event) {
    event.preventDefault();
    if (this.data.get("hidden")) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.contentTarget.classList.remove("hidden");
    this.data.set("hidden", false);
  }

  hide() {
    this.contentTarget.classList.add("hidden");
    this.data.set("hidden", true);
  }
}
