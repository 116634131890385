import { Controller } from "stimulus";

export default class extends Controller {
  hide(event) {
    event.preventDefault();
    event.stopPropagation();
    window.parent.postMessage({ function: "hideIframe" }, "*");
  }

  escape(event) {
    if (event.keyCode == 27 || event.type == "click") {
      this.hide(event);
    }
  }
}
