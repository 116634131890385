import consumer from "./consumer";

consumer.subscriptions.create("PaymentsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    var collection = document.querySelector("[data-channel='payments']");
    if (collection) {
      collection.insertAdjacentHTML("beforeend", data.payment);
    }
  },
});
