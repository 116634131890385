import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["account", "card", "token", "form", "submit", "errors"];

  connect() {
    this.mountStripe();
  }

  generateToken(event) {
    event.preventDefault();

    this.submitTargets.forEach((submit) => (submit.disabled = "disabled"));

    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        this.errorsTarget.textContent = result.error.message;
        this.submitTargets.forEach((submit) =>
          submit.removeAttribute("disabled")
        );
      } else {
        this.stripeTokenHandler(result.token);
      }
    });
  }

  // private

  stripeTokenHandler(token) {
    this.tokenTarget.value = token.id;
    this.formTarget.submit();
  }

  mountStripe() {
    const pk = document.querySelector('meta[name="stripe-pk"]').content;

    this.stripe = Stripe(pk, {
      stripeAccount: this.data.get("account"),
    });

    const elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Nunito:400,600,700",
        },
      ],
    });
    this.card = elements.create("card", {
      style: {
        base: {
          fontFamily: "Nunito",
          fontSize: "16px",
        },
      },
    });
    this.card.mount(this.cardTarget);
  }
}
